import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { formatRange } from '../utils';
import { Grid } from '@mui/material';

export const FieldLayout = ({ label, content }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={4}>
      <Typography variant="subtitle2" gutterBottom>{label}:</Typography>
    </Grid>
    <Grid item xs={12} md={8}>
      {content}
    </Grid>
  </Grid>
);

const FieldValue = ({ label, value, url, range }) => (
  <>
    <hr />
    <FieldLayout
      label={label}
      content={<Typography sx={{ whiteSpace: "pre-line" }} variant='body2' gutterBottom>{url ? <Link href={url} target="_blank" rel="noopener noreferrer">{value || url}</Link> : <>{range ? formatRange(value) : value}</>}</Typography>}
    />
  </>
)

export default FieldValue;