import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { RECORDING_STATES } from '../../constants';
import { formatDollars, getRecordingUrl, parseGraphQLError } from '../../utils';
import VideoJS from '../VideoJs';
import Box from '@mui/material/Box';
import { useFeedback } from '../feedback/Service';
import { CORE_DESIRED_POSITION_FIELDS } from '../../gql/fragments/desiredPosition.fragment';
import { useMutation, gql } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import FieldValue from '../FieldValue';
import { needsInterview } from './TalentDetails';
import { useMediaQuery, useTheme } from '@mui/material';

const DELETE_DESIRED_POSITION = gql`
  ${CORE_DESIRED_POSITION_FIELDS}
  mutation deleteDesiredPosition($_id: String!) {
    deleteDesiredPosition(_id: $_id) {
      _id
      desiredPositions {
        ...CoreDesiredPositionFields
      }
    }
  }
`;

const getVideoJsOptions = id => ({
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: getRecordingUrl(id),
    type: 'video/mp4'
  }]
});

const TalentPositionDetails = ({ position, showEdit, showRerecord = true, introAnswers = [] }) => {
  const answers = useMemo(() => {
    const positionAnswers = position?.interview?.answers || [];
    return [...introAnswers, ...positionAnswers];
  }, [position, introAnswers]);
  const [tab, setTab] = useState("info");
  const feedback = useFeedback();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [remove, { loading: deleting }] = useMutation(DELETE_DESIRED_POSITION, {
    update(cache, { data: { deleteDesiredPosition: talent } }) {
      console.log("deleteDesiredPosition result", talent);
      cache.modify({
        fields: {
          desiredPositions(current, { readField }) {
            console.log("current", current)
            return current.filter(x => position._id !== readField('_id', x));
          },
        },
      });
    }
  });

  const handleChange = (event, newValue) => setTab(newValue);

  const handleDeleteClick = async e => {
    e.stopPropagation();
    const confirm = await feedback.confirm({ title: "Remove position?" });
    if (!confirm)
      return;
    try {
      await remove(({ variables: { _id: position._id } }));
      feedback.snackbar({ text: "Request removed!", type: "success" });
    } catch (error) {
      console.error("Delete request error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  if (!position)
    return null;

  return (
    <>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1, bgcolor: 'background.paper', display: isXs? "block" : 'flex' }}>
          <TabList
            orientation={isXs ? undefined : "vertical"}
            onChange={handleChange}
            sx={{ borderRight: 1, borderColor: 'divider', minWidth: isXs ? "100%" : 250, width: 250, height: isXs ? undefined : "65vh" }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Details" value={"info"} />
            {answers.map(({ question }) => <Tab key={question._id} label={question.title} value={question._id} />)}
          </TabList>
          <TabPanel value="info">
            <FieldValue label="Proven working experience on position, years" value={position.experience + " years"} />
            <FieldValue label="Minimum base salary" value={formatDollars(position.baseCompensation)} />
            <FieldValue label="Desired on-target earnings" value={formatDollars(position.ote)} />
            <FieldValue label="Sales instruments and business tools" value={position.tools} />
            <FieldValue label="Other preferences (industry, type of company, etc)" value={position.preferences} />
            {(needsInterview(position) && showRerecord) && <Button variant="contained" sx={{ mt: 1 }} component={Link} to={"/account/talent/position/" + position._id + "/interview"}>Start video interview</Button>}
            {showEdit && <Button variant="outlined" sx={{ mt: 1, ml: 1 }} component={Link} to={"/account/talent/position/" + position._id}>Edit</Button>}
            {showEdit && <LoadingButton variant="outlined" sx={{ mt: 1, ml: 1 }} loading={deleting} onClick={handleDeleteClick} color="error">Remove</LoadingButton>}
          </TabPanel>
          {answers.map(({ question, recording }) => (
            <TabPanel key={question._id} value={question._id}>
              {recording.state === RECORDING_STATES.READY ?
                <Container maxWidth="md">
                  <VideoJS options={getVideoJsOptions(recording._id)} />
                  {(question.canRerecord && showRerecord) && <Button component={Link} to={`/account/rerecord/${question._id}/${position._id}`} variant="outlined" sx={{ mt: 1 }}>Re-record</Button>}
                </Container> :
                <Typography variant="h6">Recording state: {recording.state}</Typography>
              }
              {recording.state === RECORDING_STATES.ERROR && <Button component={Link} to={`/account/rerecord/${question._id}/${position._id}`} variant="outlined" sx={{ mt: 1 }}>Re-record</Button>}
              <Typography sx={{ whiteSpace: 'pre-line', mt: 1 }}>{question.text}</Typography>
            </TabPanel>
          ))}
        </Box>
      </TabContext>
      {/* <FieldValue label="Desired Position" value={position.name} /> */}
    </>
  );
}

export default TalentPositionDetails;