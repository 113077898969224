// spell-checker: disable
let config = {
  apiUrl: "http://localhost:8080",
    // apiUrl: "https://api.hirecavalry.com:443",
  cloudfrontDomain: "d2it3zqmvf1iv2.cloudfront.net",
  useTestFormData: true,
  version: "0.1.17",
  googleClientId: "770402201350-f02okq1t0ocaiep3iakl5m3ispo3ebqf.apps.googleusercontent.com",
  enableGoogleLogin: true,
};

//eslint-disable-next-line
if (process.env.NODE_ENV === "production") {
  config = {
    ...config,
    useTestFormData: false,
    sentryDsn: "https://be2941f3fd30772cbe531dbb7ab639ab@o4506116358406144.ingest.sentry.io/4506116465295360",
    apiUrl: "https://api.hirecavalry.com:443",
  }
}

export default config;