import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CloseButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} sx={theme => ({ position: 'absolute', top: theme.spacing(1), right: theme.spacing(1) })}>
      <CloseIcon />
    </IconButton>
  );
}

export default CloseButton;