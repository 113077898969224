import React, { useEffect } from 'react';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';

const Stepper = ({ steps, activeStep, setStep, isRejected }) => {
  const [isVertical, setIsVertical] = React.useState(false);
  const ref = React.useRef();
  const handleClick = index => () => setStep(index);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (isSm) {
      setIsVertical(true)
    }
    if (ref.current) {
      const stepperWidth = ref.current.scrollWidth;
      const parentWidth = ref.current.parentElement.scrollWidth;
      console.log("stepperWidth", stepperWidth, "parentWidth", parentWidth);
      console.dir(ref.current)
      if (stepperWidth > parentWidth) {
        setIsVertical(true);
      }
    }
  }, [isSm]);

  return (
    <MuiStepper activeStep={activeStep} alternativeLabel={!isVertical && steps.length > 4} orientation={isVertical ? "vertical" : undefined} ref={ref}>
      {steps.map((step, index) => {
        const stepProps = { completed: !!step.completed && index !== activeStep, disabled: step.disabled };
        const labelProps = {};
        if (step.optional) {
          labelProps.optional = (
            <Typography variant="caption">Optional</Typography>
          );
        }
        if (step.rejectable && isRejected) {
          labelProps.error = true;
          step.clickable = false;
          step.label = "Application rejected";
        }
        return (
          <Step key={step.id} {...stepProps}>
            {(step.clickable && setStep) ?
              <StepButton {...labelProps} onClick={handleClick(index)}>{step.label}</StepButton> :
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            }
          </Step>
        );
      })}
    </MuiStepper>
  );
}

export default Stepper;