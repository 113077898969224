import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { CORE_TALENT_FIELDS } from '../../gql/fragments/talent.fragment';
import { loaderVar } from '../../vars';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CORE_DESIRED_POSITION_FIELDS } from '../../gql/fragments/desiredPosition.fragment';
import { Container, Link } from '@mui/material';
import TalentProfile from './TalentProfile';
import VideoJS from '../VideoJs';
import { RECORDING_STATES } from '../../constants';
import { getRecordingUrl } from '../../utils';
import { useFeedback } from '../feedback/Service';
import TalentPositionDetails from './TalentPositionDetails';
import { FieldLayout } from '../FieldValue';
import { useMediaQuery, useTheme } from '@mui/material';

const getVideoJsOptions = id => ({
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: getRecordingUrl(id),
    type: 'video/mp4'
  }]
});

const TALENT = gql`
  ${CORE_TALENT_FIELDS}
  ${CORE_DESIRED_POSITION_FIELDS}
  query GetTalent($id: String!) {
    talent(id: $id) {
      ...CoreTalentFields
      desiredPositions {
        ...CoreDesiredPositionFields
      }
      user {
        _id
        # email
        firstName
        lastName
      }
      interview {
        answers {
          recording {
            _id
            state
          }
          question {
            _id
            text
            title
          }
        }
      }
    }
  }
`;

const TalentPage = () => {
  const { talentId } = useParams();
  const { loading, error, data } = useQuery(TALENT, { variables: { id: talentId } });
  const feedback = useFeedback();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const videoIntroRecording = useMemo(() => {
    const answer = data?.talent?.interview?.answers?.find(x => x);
    if (!answer)
      return null;
    if (answer.recording.state === RECORDING_STATES.READY)
      return answer.recording
    return null;
  }, [data]);

  const handleShowPosition = index => () => {
    console.log("Show position", index);
    const selectedPosition = data.talent.desiredPositions[index];
    feedback.alert({
      title: selectedPosition.position.name,
      component: <TalentPositionDetails showRerecord={false} position={selectedPosition} />,
      scroll: "body", maxWidth: "lg", fullWidth: true, clickAwayClose: true, confirmButtonText: "Close", fullScreen: isXs
    });
  }

  useEffect(() => {
    loaderVar(loading ? "query" : null);
  }, [loading]);

  if (error)
    return <div>{error.message}</div>

  if (!data)
    return null;

  const { talent } = data;
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ p: 2, pb: 1 }}>
          <Typography variant="h4" gutterBottom>{talent.user.firstName} {talent.user.lastName}</Typography>
          {videoIntroRecording &&
            <FieldLayout
              label="Video Introduction"
              content={<VideoJS options={getVideoJsOptions(videoIntroRecording._id)} />}
            />
          }
          <TalentProfile talent={talent} />
          <hr />
          {talent.desiredPositions.length > 0 &&
            <FieldLayout
              label="Desired positions"
              content={talent.desiredPositions.map((position, index) => <Link sx={{ display: "block" }} variant='body2' underline="hover" href="#" key={position._id} onClick={handleShowPosition(index)}>{position.position.name}</Link>)}
            />
          }
        </Box>
      </Box>
    </Container>
  );
}

export default TalentPage;