import config from "./config";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export const getRecordingUrl = id => `//${config.cloudfrontDomain}/${id}.mp4`;

export const formatRange = ({ from, to }) => {
  if (to === 0)
    return "Not applicable";
  return from !== to ? `${formatDollars(from)} – ${formatDollars(to)}` : "$" + from;
}

export const formatDollars = value => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);

export const transformNumericFields = (formData, numericFields) => {
  // let formData = { ...data };
  // console.log("transformNumericFields", formData, numericFields);
  for (let field of numericFields) {
    if (field.includes(".")) {
      let parts = field.split(".");
      if (Array.isArray(formData[parts[0]])) {
        for (let x of formData[parts[0]]) {
          transformNumericFields(x, [field.replace(parts[0] + ".", "")]);
        }
      } else
        transformNumericFields(formData[parts[0]], [field.replace(parts[0] + ".", "")]);
      continue;
    }
    if (typeof formData[field] === "object") {
      formData[field] = { from: parseFloat(formData[field].from), to: parseFloat(formData[field].to) };
      if (formData[field].from > formData[field].to)
        return;
    } else
      formData[field] = parseFloat(formData[field]);
  }
}

export const formatCommission = commission => {
  const { unit, value, type, quantity } = commission;
  let result = "";
  if (unit === "DOLLAR")
    result += "$";
  result += value;
  if (unit === "PERCENT")
    result += "%";
  result += (unit === "DOLLAR" ? " for " : " of ") + (quantity !== 1 ? (quantity + " ") : "") + type;
  return result;
}

export const formatCommissions = commissions => commissions.map(x => formatCommission(x)).join("; ");

export const formatTimeRange = (start, end) => {
  const startTime = dayjs(start);
  const endTime = dayjs(end);
  return startTime.format("LT") + " – " + endTime.format("LT");
}

export const formatWorkExperience = item => `${item.position} at ${item.company} ${item.startMonth}/${item.startYear} - ${item.isCurrent ? 'Present' : `${item.endMonth || ""}/${item.endYear || ""}`}`;

export const formatEducation = item => `${item.degree} at ${item.school} ${item.endYear}`;

export const parseGraphQLError = error => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0)
    return error.graphQLErrors[0].message;
  return error.message;
}

export const wait = (func, timeout) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      func().then(resolve).catch(reject);
    }, timeout);
  });
}

export const formatTimestamp = timestamp => dayjs(Number(timestamp)).tz(dayjs.tz.guess()).format("lll")