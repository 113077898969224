import React, { createContext, useContext, useState, useRef } from 'react';
import Dialog from './FeedbackDialog';
import Snackbar from './FeedbackSnackbar';

const Context = createContext(Promise.reject);

export const useFeedback = () => useContext(Context);

export const FeedbackProvider = ({ children, level = 0 }) => {
  const [confirmationState, setConfirmationState] = useState(null);
  const [snackbarState, setSnackbarState] = useState(null);
  const awaitingPromiseRef = useRef();
  const confirm = (options, isAlert = false) => {
    setConfirmationState({ ...options, isAlert });
    if (isAlert) return;
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };
  const snackbar = (options) => setSnackbarState(options);
  const handleClose = () => {
    if (!awaitingPromiseRef.current)
      return;
    if (!confirmationState.isAlert && confirmationState.catchOnCancel)
      awaitingPromiseRef.current.reject();
    else
      awaitingPromiseRef.current.resolve(false);
    setConfirmationState(null);
  };
  const handleSnackbarClose = () => setSnackbarState(null);
  const handleSubmit = (value) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(value);
    }
    setConfirmationState(null);
  };

  if (level > 10)
    return null;

  return (
    <>
      <Context.Provider
        value={{ snackbar, confirm, alert: options => confirm(options, true), form: options => confirm({ ...options, maxWidth: 'sm', fullWidth: true }) }}
      >
        {children}
      </Context.Provider>
      <FeedbackProvider level={level + 1}>
        <Dialog
          open={!!confirmationState}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          level={level}
          {...confirmationState}
        />
      </FeedbackProvider>
      <Snackbar
        open={!!snackbarState}
        handleClose={handleSnackbarClose}
        {...snackbarState}
      />
    </>
  );
};