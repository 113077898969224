import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import DataGrid from '../../DataGrid';
import { formatEducation, formatRange, formatTimeRange, formatTimestamp, formatWorkExperience, parseGraphQLError } from '../../../utils';
import { RECORDING_STATES, TALENT_STATES_LABELS } from '../../../constants';

import { useMutation, gql } from '@apollo/client';
import { useFeedback } from '../../feedback/Service';

const DELETE_TALENT = gql`
  mutation DeleteTalent($id: String!) {
    deleteTalent(id: $id)
  }
`;

const cols = [
  { accessorFn: ({ user }) => user.firstName + " " + user.lastName, header: 'Name', size: 150, pin: true, id: "name" },
  { accessorFn: ({ user }) => user.email, header: 'E-mail', size: 150, pin: true, id: "email" },
  { accessorKey: '_id', header: 'ID', size: 220, enableHiding: true, hide: true, enableClickToCopy: true },
  { accessorFn: x => TALENT_STATES_LABELS[x.state], header: 'State', size: 22, filterVariant: 'multi-select' },
  // { field: 'positionsText', headerName: 'Desired Positions', width: 450, editable: false, valueFormatter: ({ value }) => value.name },
  { accessorFn: ({ interview: value }) => value ? (value.answers.filter(x => x.recording?.state === RECORDING_STATES.READY).length ? "true" : "false") : "false", header: 'Intro', size: 180, filterVariant: 'checkbox', },
  { accessorFn: ({ desiredPositions }) => desiredPositions.some(dp => dp.interview?.answers?.filter(a => a?.recording?.state === RECORDING_STATES.READY).length === dp.position.questions.length) ? "true" : "false", header: 'Interview', size: 180, filterVariant: 'checkbox', },
  { accessorKey: 'totalExperience', header: 'Exp.', size: 100, filterVariant: "range" },
  { accessorFn: ({ dealSize }) => formatRange(dealSize), header: 'Avg. Deal', size: 100, filterVariant: "multi-select" },
  { accessorKey: 'contractCycle', header: 'Avg. Contract Cycle', size: 10, filterVariant: "multi-select" },
  { accessorFn: ({ businesses }) => businesses.join(", "), header: 'Segments', size: 250, filterVariant: 'multi-select', filterField: "businesses" },
  { accessorFn: ({ products }) => products.join(", "), header: 'Products', size: 250, filterVariant: 'multi-select', filterField: "products" },
  { accessorFn: ({ level }) => level.join(", "), header: 'Levels', size: 250, filterVariant: 'multi-select', filterField: "level" },
  { accessorFn: ({ workExperience }) => workExperience.map(formatWorkExperience).join("; "), header: 'Work Experience', size: 250, enableHiding: true },
  { accessorFn: ({ education }) => education.map(formatEducation).join("; "), header: 'Education', size: 250, enableHiding: true },
  { accessorFn: ({ skills }) => skills.join(", "), header: 'Skills', size: 250, filterVariant: 'multi-select', filterField: "skills" },
  { accessorFn: ({ engagement = [] }) => (engagement || []).join(", "), header: 'Engagement', size: 250, filterVariant: 'multi-select', filterField: "engagement" },
  { accessorFn: ({ remote }) => remote ? "true" : "false", header: 'Remote', size: 25, filterVariant: 'checkbox', },
  { accessorFn: ({ startTime, endTime }) => formatTimeRange(startTime, endTime), header: 'Working hours', size: 25, },
  { accessorFn: ({ user }) => user.leadType || "None", header: 'Lead Type', size: 150 },
  { accessorFn: ({ createdAt }) => formatTimestamp(createdAt), header: 'Created', size: 150 },
  { accessorFn: ({ updatedAt }) => formatTimestamp(updatedAt), header: 'Updated', size: 150 },
];

const TalentsGrid = ({ data: initialData, handleShowDetails, height, ...rest }) => {
  const [data, setData] = useState([]);
  // const [editedRows, setEditedRows] = useState([]);
  const [deleteTalent, { loading: isPosting }] = useMutation(DELETE_TALENT, { refetchQueries: ['GetTalents'] });
  const feedback = useFeedback();

  const handleRowDoubleClick = ({ _id }) => handleShowDetails(_id);

  useEffect(() => {
    if (initialData?.talents)
      setData(initialData?.talents);
  }, [initialData]);

  const handleRowDelete = async (id) => {
    try {
      console.log("deleting talent", id);
      const res = await deleteTalent(({ variables: { id } }));
      console.log("talent deleted", res);
      feedback.snackbar({ text: "Talent deleted", type: "success" });
    } catch (error) {
      console.error("Delete talent error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
  }

  return (
    <Box sx={{ height: height || 'calc(100vh - 128px)', width: '100%' }}>
      <DataGrid
        data={data}
        columns={cols}
        // onEditCellChangeCommitted={handleCellChangeCommitted}
        // editedRows={editedRows}
        // onSave={handleSaveChanges}
        isPosting={isPosting}
        onRowDoubleClick={handleRowDoubleClick}
        onDelete={handleRowDelete}
        {...rest}
      />
    </Box>
  );
}

export default TalentsGrid;