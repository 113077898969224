export const AUTH_TOKEN_NAME = "cavalry-token";
export const ROLES = ["USER", "ADMIN"];

export const RECORDING_STATES = {
  CREATED: "CREATED",
  PROCESSING: "PROCESSING",
  READY: "READY",
  ERROR: "ERROR"
};

export const TALENT_STATES = {
  NEW: "NEW",
  NEEDS_EDIT: "NEEDS_EDIT",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const TALENT_STATES_LABELS = {
  NEW: "New",
  NEEDS_EDIT: "Needs Editing",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const TALENT_STATES_COLORS = {
  NEW: "info",
  NEEDS_EDIT: "warning",
  APPROVED: "success",
  REJECTED: "error",
};