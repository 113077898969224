import React, { useReducer, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import PositionSelect from '../inputs/PositionSelect';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, gql } from '@apollo/client';
import { useFeedback } from '../feedback/Service';
import ProductSelect from '../inputs/ProductSelect';
import BusinessesSelect from '../inputs/BusinessesSelect';
import LevelSelect from '../inputs/LevelSelect';
import SkillsSelect from '../inputs/SkillsSelect';
import UrlInput from '../inputs/UrlInput';
import { socialMediaDictionary } from '../../dictionaries/social';
import DealSizeSelect, { data as dealSizeData } from '../inputs/DealSizeSelect';
import ContractCycleSelect from '../inputs/ContractCycleSelect';
import { CORE_TALENT_FIELDS } from '../../gql/fragments/talent.fragment';
import { useNavigate } from 'react-router';
import config from '../../config';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import WorkExperience from './WorkExperience';
import Education from './Education';
import WorkingHours from '../inputs/WorkingHoursInput';
import EngagementSelect from '../inputs/EngagementSelect';
import { parseGraphQLError } from '../../utils';
// import RangeInput from '../inputs/RangeInput';

dayjs.extend(utc);
dayjs.extend(timezone);

const CREATE_TALENT = gql`
  ${CORE_TALENT_FIELDS}
  mutation CreateTalent($talent: TalentInput!) {
    createTalent(talent: $talent) {
      ...CoreTalentFields
    }
  }
`;

// const socialLinks = [
//   { name: "linkedin", baseUrl: "https://www.linkedin.com/in/" },
//   { name: "twitter", baseUrl: "https://twitter.com/" },
//   { name: "facebook", baseUrl: "https://www.facebook.com/" },
//   { name: "instagram", baseUrl: "https://www.instagram.com/" },
// ];

const defaultStartDate = dayjs().startOf('day').hour(10);
const defaultEndDate = dayjs().startOf('day').hour(19);

let initialState = {
  totalExperience: "",
  products: [],
  dealSize: "",
  contractCycle: "",
  businesses: [],
  level: [],
  skills: [],
  engagement: [],
  achievements: "",
  linkedin: "",
  facebook: "",
  twitter: "",
  instagram: "",
  remote: false,
  startTime: dayjs(defaultStartDate),
  endTime: dayjs(defaultEndDate),
  workExperience: [],
  education: [],
}

if (config.useTestFormData) {
  initialState = {
    ...initialState,
    totalExperience: "10",
    products: [],
    dealSize: "",
    contractCycle: "1 week",
    businesses: ["Mid Market"],
    level: [],
    skills: [],
    engagement: [],
    achievements: "none",
    linkedin: "test",
    facebook: "test",
    twitter: "test",
    instagram: "test",
    remote: false,
  }
}

const numericFields = ["totalExperience"];
const dateFields = ["startTime", "endTime"];

const reducer = (state, newState) => ({
  ...state,
  ...newState
})


const ApplicationForm = ({ userId, data: initialData, redirect }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const feedback = useFeedback();
  const navigate = useNavigate();
  const [submit, { loading }] = useMutation(CREATE_TALENT, {
    update(cache, { data: { createTalent } }) {
      cache.modify({
        id: cache.identify({ _id: userId, __typename: "User" }),
        fields: {
          talent: () => createTalent,
        }
      });
    }
  });

  const handleChange = ({ target: { name, value, checked, type } }) => {
    setState({ [name]: type === "checkbox" ? checked : value /* (type === "number" ? parseFloat(value) : value) */ });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = { ...state };
      for (let field of numericFields) {
        if (typeof data[field] === "object") {
          data[field] = { from: parseFloat(data[field].from), to: parseFloat(data[field].to) };
          if (data[field].from > data[field].to)
            return;
        } else
          data[field] = parseFloat(data[field]);
      }
      for (let field of dateFields) {
        data[field] = dayjs(data[field]).tz(dayjs.tz.guess()).toISOString();
      }
      console.log({ data });
      // if (data) return;
      await submit(({ variables: { talent: data } }));
      if (!initialData?._id) {
        window.gtag('event', 'talent_profile', {});
      }
      feedback.snackbar({ text: "Form submitted!", type: "success" });
      if (redirect)
        navigate(redirect);
    } catch (error) {
      console.error("TalentForm error", error);
      feedback.snackbar({ text: parseGraphQLError(error), type: "error" });
    }
    // console.log(talent);
  }

  useEffect(() => {
    if (!initialData)
      return;
    const dealSize = dealSizeData.find(({ value }) => value.from === initialData.dealSize.from && value.to === initialData.dealSize.to).value;
    const dates = {};
    for (let field of dateFields) {
      dates[field] = dayjs(initialData[field] || defaultStartDate).tz(dayjs.tz.guess());
    }
    setState({
      ...initialData,
      ...dates,
      dealSize,
      __typename: undefined,
      desiredPositions: undefined,
      interview: undefined,
      workExperience: initialData.workExperience.map(({ __typename, ...rest }) => rest),
      education: initialData.education.map(({ __typename, ...rest }) => rest),
      state: undefined,
      engagement: initialData.engagement || [],
      createdAt: undefined,
      updatedAt: undefined,
    });
  }, [initialData]);

  console.log({ state });

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField fullWidth multiline rows={5} required value={state.achievements} onChange={handleChange} name="achievements" label="Short intro" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField fullWidth required value={state.totalExperience} type="number" onChange={handleChange} name="totalExperience" label="Proven working experience in sales, years" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DealSizeSelect required value={state.dealSize} onChange={handleChange} name="dealSize" label="Your average deal size" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractCycleSelect required value={state.contractCycle} onChange={handleChange} name="contractCycle" label="Your average sales cycle" />
        </Grid>
        <Grid item xs={12}>
          <BusinessesSelect required fullWidth value={state.businesses} onChange={handleChange} name="businesses" label="Customer segment" />
        </Grid>
        <Grid item xs={12} md={12}>
          <ProductSelect fullWidth required value={state.products} onChange={handleChange} name="products" label="Products sold" />
        </Grid>
        <Grid item xs={12}>
          <LevelSelect fullWidth required value={state.level} onChange={handleChange} name="level" label="Level sold to" />
        </Grid>
        <Grid item xs={12}>
          <WorkExperience items={state.workExperience} onChange={workExperience => setState({ workExperience })} />
        </Grid>
        <Grid item xs={12}>
          <Education items={state.education} onChange={education => setState({ education })} />
        </Grid>
        <Grid item xs={12}>
          <SkillsSelect fullWidth required value={state.skills} onChange={handleChange} name="skills" label="Top skills, choose up to 10 options" />
        </Grid>
        <Grid item xs={12}>
          <EngagementSelect fullWidth required value={state.engagement} onChange={handleChange} name="engagement" label="Preferred engagement" />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={state.remote} onChange={handleChange} name="remote" />}
            label="I can work remotely"
            name="remote"
          />
        </Grid>
        <WorkingHours startTime={state.startTime} endTime={state.endTime} onChange={handleChange} label={"Between what hours are you willing to work (assuming an 8 or 4 hour work day)?"} helperText={"Ensure you select your preferred working hours by choosing both the start and end times. We automatically adjust for your local timezone, so you only need to think about your schedule in local time. Whether you're comfortable with an 8-hour or 4-hour workday, or even open to round-the-clock availability, feel free to customize your working hours."} />
        <Grid item xs={12} md={12}>
          <UrlInput fullWidth value={state.linkedin} onChange={handleChange} name="linkedin" label="LinkedIn" baseUrl={socialMediaDictionary.linkedin.baseUrl} />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <UrlInput fullWidth value={state.twitter} onChange={handleChange} name="twitter" label="Twitter" baseUrl={socialMediaDictionary.twitter.baseUrl} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UrlInput fullWidth value={state.facebook} onChange={handleChange} name="facebook" label="Facebook" baseUrl={socialMediaDictionary.facebook.baseUrl} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UrlInput fullWidth value={state.instagram} onChange={handleChange} name="instagram" label="Instagram" baseUrl={socialMediaDictionary.instagram.baseUrl} />
        </Grid> */}
      </Grid>
      <LoadingButton type="submit" size="large" variant="contained" sx={{ mt: 2, mb: 2 }} loading={loading}>
        Submit
      </LoadingButton>
    </Box>
  );
}

export default ApplicationForm;